// extracted by mini-css-extract-plugin
export var button = "sign-in-page-module--button--Ppjpo";
export var canadianSupply = "sign-in-page-module--canadianSupply--Na5vX";
export var ccs = "sign-in-page-module--ccs--5h5YK";
export var contactDetailsIcon = "sign-in-page-module--contactDetailsIcon--ijcCg";
export var contactForm = "sign-in-page-module--contactForm--FEY+m";
export var contactFormInputs = "sign-in-page-module--contactFormInputs--DDsiR";
export var display = "sign-in-page-module--display--b-nfJ";
export var email = "sign-in-page-module--email--6PdOV";
export var footer = "sign-in-page-module--footer--38W35";
export var footerContent = "sign-in-page-module--footerContent--UwdVa";
export var footerL = "sign-in-page-module--footerL--wJE9j";
export var footerLinks = "sign-in-page-module--footerLinks--HZnnD";
export var footerR = "sign-in-page-module--footerR--RNGv1";
export var formTitle = "sign-in-page-module--formTitle--mF8Ae";
export var ifYouAreNotRegisteredGo = "sign-in-page-module--ifYouAreNotRegisteredGo--z+kMk";
export var input = "sign-in-page-module--input--z1WeH";
export var inputFieldWIthLabel = "sign-in-page-module--inputFieldWIthLabel--SlfS1";
export var inputLabel = "sign-in-page-module--inputLabel--N5BVW";
export var inputLabel2 = "sign-in-page-module--inputLabel2--NO8GF";
export var inputRow = "sign-in-page-module--inputRow--ZszOC";
export var logo = "sign-in-page-module--logo--uwhTF";
export var privacyPolicy = "sign-in-page-module--privacyPolicy--kI807";
export var registerContent = "sign-in-page-module--registerContent--7wI2U";
export var registerPage = "sign-in-page-module--registerPage--y802Z";
export var registerSection = "sign-in-page-module--registerSection--cudtt";
export var signInPage = "sign-in-page-module--signInPage--EZuSi";
export var tcpSupplyLogoIcon = "sign-in-page-module--tcpSupplyLogoIcon--qILJ8";
export var tcpSupplyLogoIcon1 = "sign-in-page-module--tcpSupplyLogoIcon1--loQor";
export var visaIcon = "sign-in-page-module--visaIcon--6idCw";