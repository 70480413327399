import * as React from "react";
import { FunctionComponent, useCallback } from "react";
import { Input, Button, Text, InputRightElement, InputGroup } from "@chakra-ui/react";
import { Link } from "gatsby";
import * as styles from "./sign-in-page.module.css";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { GET_USER } from "../useAuth";
import { gql, useMutation } from "@apollo/client";
import { useState } from "react";

const LOG_IN = gql`
  mutation logIn($login: String!, $password: String!) {
    loginWithCookies(input: { login: $login, password: $password }) {
      status
    }
  }
`;


const SignInPage: FunctionComponent = () => {  
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const [logIn, { loading, error }] = useMutation(LOG_IN, {
    refetchQueries: [
      {
        query: GET_USER,
      },
    ],
  });

  const errorMessage = error?.message || "";
  const isEmailValid =
    !errorMessage.includes("empty_email") &&
    !errorMessage.includes("empty_username") &&
    !errorMessage.includes("invalid_email") &&
    !errorMessage.includes("invalid_username");
  const isPasswordValid =
    !errorMessage.includes("empty_password") &&
    !errorMessage.includes("incorrect_password");

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const data: any = new FormData(event.currentTarget);

    const values = Object.fromEntries(data);

    const email = values.login;
    const password = values.password;

    logIn({
      variables: {
        login: email,
        password,
      },
    }).catch((error) => {
      console.error(error);
    });
  }

  return (
    <div className={styles.signInPage}>
      <div className={styles.registerSection}>
        <div className={styles.registerContent}>
          <div className={styles.contactForm}>
          <form method="post" onSubmit={handleSubmit} className={styles.contactFormInputsDiv}>
            <div className={styles.contactFormInputs}>
            <div className={styles.logo}>
                <a href="/">
                <img
                  className={styles.tcpSupplyLogoIcon}
                  alt=""
                  src="/tcpsupply-logo4.svg"
                />
                </a>
              </div>
              <div className={styles.formTitle}>
                <div className={styles.display}>Sign In</div>
              </div>
              <div className={styles.inputRow}>
                <div className={styles.inputFieldWIthLabel}>
                  <div className={styles.inputLabel}>
                    <div className={styles.email}>Email</div>
                  </div>
                  <Input
                    id="log-in-email"
                    className={styles.input}
                    variant="outline"
                    textColor="#1a202c"
                    borderColor="#e2e8f0"
                    focusBorderColor="#3182ce"
                    placeholder="johndoe@email.com"
                    type="email"
                    name="login"
                    autoComplete="username"
                    required
                  />
                  {!isEmailValid ? (
                    <Text color="red" className="error-message">
                      Invalid email. Please try again.
                    </Text>
                  ) : null}
                </div>
              </div>
              <div className={styles.inputRow}>
                <div className={styles.inputFieldWIthLabel}>
                  <div className={styles.inputLabel}>
                    <div className={styles.email}>Password</div>
                  </div>
                  <InputGroup size="md">
                  <Input
                    className={styles.input}
                    variant="outline"
                    textColor="#1a202c"
                    borderColor="#e2e8f0"
                    focusBorderColor="#3182ce"
                    type={show ? "text" : "password"}
                    id="log-in-password"
                    name="password"
                    autoComplete="current-password"
                  />
                  
                  <InputRightElement width="4.5rem">
                    <Button size="sm" onClick={handleClick} mt="2" bg="transparent">
                      {show ? <AiFillEye /> : <AiFillEyeInvisible />}
                    </Button>
                  </InputRightElement>
                  </InputGroup>
                  {!isPasswordValid ? (
                    <Text color="red" className="error-message">
                      Invalid password. Please try again.
                    </Text>
                  ) : null}
                  <div className={styles.inputLabel2}>
                    <div className={styles.ifYouAreNotRegisteredGo}>
                      <span>{`If you are not registered, go to `}</span>
                      <Link to="/register" color="#3b6477" className={styles.registerPage}>
                        Register page
                      </Link>
                      <span>
                        {" "}
                        and follow the prompts to create your account. Once you
                        have registered, you can sign in using your
                        newly-created username and password.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <Button
                className={styles.button}
                variant="solid"
                colorScheme="tcpcyan"
                type="submit"
              >
                {loading ? 'Signing In...' : 'Sign In'}
              </Button>
            </div>
            </form>
          </div>
        </div>
        <img
          className={styles.contactDetailsIcon}
          alt=""
          src="/contactdetails@2x.png"
        />
      </div>
    </div>
  );
};

export default SignInPage;
